import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useLayoutEffect, useEffect } from "react";
import { BannerContent, bannerOverlayInlineStyle, bannerOverlayInlineStyleAlignment, bannerContentInlineStyleInner } from ".";
import { useBanner } from "../../contexts";
import { useRestrictScrollWhenVisible, useResettableAnimation, usePlainCss, useBannerActionType, useBannerActionLinks, useBannerAnimation } from "../../hooks";
import { AnimatedCss } from "..";
import { getOtherOptionsFromWindow } from "../../utils";
import { isHiddenDueLegal } from "../../others";
import { dispatchResizeEvent } from "@devowl-wp/headless-content-unblocker";

var BannerAnimation = function BannerAnimation() {
  var banner = useBanner();
  var _banner$layout = banner.layout,
      animationInDuration = _banner$layout.animationInDuration,
      animationOutDuration = _banner$layout.animationOutDuration,
      animationVisible = banner.animationVisible;

  var _useBannerAnimation = useBannerAnimation()(banner.layout),
      useAnimationIn = _useBannerAnimation.useAnimationIn,
      useAnimationOut = _useBannerAnimation.useAnimationOut;

  var _useResettableAnimati = useResettableAnimation(useAnimationIn, animationInDuration),
      _useResettableAnimati2 = _slicedToArray(_useResettableAnimati, 2),
      stateAnimationIn = _useResettableAnimati2[0],
      stateAnimationInDuration = _useResettableAnimati2[1];

  var _useResettableAnimati3 = useResettableAnimation(useAnimationOut === "none" ? "fadeOut" : useAnimationOut, useAnimationOut === "none" ? 0 : animationOutDuration),
      _useResettableAnimati4 = _slicedToArray(_useResettableAnimati3, 2),
      stateAnimationOut = _useResettableAnimati4[0],
      stateAnimationOutDuration = _useResettableAnimati4[1];

  return h(AnimatedCss, _extends({
    animationIn: stateAnimationIn,
    animationInDuration: stateAnimationInDuration,
    animationOut: stateAnimationOut,
    animationOutDuration: stateAnimationOutDuration,
    isVisible: animationVisible
  }, bannerContentInlineStyleInner(banner)), h(BannerContent, null));
};

var Banner = function Banner() {
  var banner = useBanner();

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      isPro = _getOtherOptionsFromW.isPro;

  var _banner$layout2 = banner.layout,
      overlay = _banner$layout2.overlay,
      overlayBlur = _banner$layout2.overlayBlur,
      _banner$customCss = banner.customCss,
      antiAdBlocker = _banner$customCss.antiAdBlocker,
      css = _banner$customCss.css,
      visible = banner.visible,
      animationVisible = banner.animationVisible,
      activeAction = banner.activeAction,
      skipOverlay = banner.skipOverlay,
      gotHiddenDueLegal = banner.gotHiddenDueLegal,
      pageRequestUuid4 = banner.pageRequestUuid4,
      individualPrivacyOpen = banner.individualPrivacyOpen;
  var hiddenDueLegal = isHiddenDueLegal(banner);
  var show = visible && !hiddenDueLegal || !!activeAction;
  usePlainCss(antiAdBlocker ? css : "");
  usePlainCss("#".concat(pageRequestUuid4, ", #").concat(pageRequestUuid4, " * {box-sizing: border-box;}")); // Blur effect

  if (isPro) {
    /* Silence is golden... Until you have PRO Version! */
  }

  useRestrictScrollWhenVisible(show && overlay && animationVisible); // Listen to window resizes and resize the content automatically

  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      setInvalidate = _useState2[1];

  var updateSize = function updateSize() {
    return setInvalidate(+new Date());
  };

  useLayoutEffect(function () {
    window.addEventListener("resize", updateSize);
    return function () {
      return window.removeEventListener("resize", updateSize);
    };
  }, []);
  useLayoutEffect(function () {
    dispatchResizeEvent(1000);
  }, [animationVisible, individualPrivacyOpen]); // When changes to the legal settings are done, make the banner visible or hide

  gotHiddenDueLegal && useEffect(function () {
    gotHiddenDueLegal(hiddenDueLegal);
  }, [hiddenDueLegal]);
  useBannerActionType();
  useBannerActionLinks();

  if (!show) {
    return null;
  }

  var content = h("div", bannerOverlayInlineStyleAlignment(banner), h(BannerAnimation, null));
  return skipOverlay ? content : h("div", _extends({
    id: pageRequestUuid4
  }, bannerOverlayInlineStyle(banner)), content);
};

export { Banner };